import { Chip } from '@mui/material';
import React from 'react';
import apiRefMessages from '../../../utils/messages/en/apiRefMessages';
import theme, { COLORS } from '../../../utils/theme';
import Typography from '../../common/Typography';

const useStyles = {
  chip: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(0),
    height: '17px',
    '& .MuiChip-label': {
      padding: '2px 4px 0px 4px',
    },
    backgroundColor: COLORS.TEXT_SECONDARY,
    margin: '1px 4px 1px 0px',
  },
  chipLabelStyle: {
    color: theme.palette.common.white,
  },
};

const ParamDetail: React.FC<{ param: any }> = (props) => {
  const { param } = props;
  const getDefaultValue = (param) => {
    if (param.default || param.default===0) {
      return param.default.toString();
    } else {
      if (
        param.schema &&
        (param.schema.default || param.schema.default === 0)
      ) {
        return param.schema.default.toString();
      } else {
        return undefined;
      }
    }
  };
  const getEnumValue = (param) => {
    if (param.enum) {
      return param.enum;
    } else {
      if (param.schema && param.schema.enum) {
        return param.schema.enum;
      } else {
        return undefined;
      }
    }
  };
  const buildDefaultValue = (param) => {
    if (getDefaultValue(param) && getEnumValue(param)) {
      return (
        <Typography
          type="DESKTOP_BODY_PARAGRAPH"
          component="div"
        >
          {apiRefMessages.defaultTitle}
          <Chip
            label={
              <Typography
                type="DESKTOP_CODE_ALLCAPS"
                sx={useStyles.chipLabelStyle}
              >
                {getDefaultValue(param)}
              </Typography>
            }
            sx={useStyles.chip}
          />
        </Typography>
      );
    } else {
      return (
        <Typography
          type="DESKTOP_BODY_PARAGRAPH"
          component="div"
        >
          {apiRefMessages.defaultTitle + getDefaultValue(param)}
        </Typography>
      );
    }
  };
  return (
    <>
      {getDefaultValue(param) && buildDefaultValue(param)}
      {getEnumValue(param) && 
        <Typography
          type="DESKTOP_BODY_PARAGRAPH"
          component="div"
        >
          {apiRefMessages.enumTitle}
          {getEnumValue(param).map((data, idx) => {
            return (
              <Chip
                label={
                  <Typography
                    type="DESKTOP_CODE_ALLCAPS"
                    sx={useStyles.chipLabelStyle}
                  >
                    {data}
                  </Typography>
                }
                key={idx}
                sx={useStyles.chip}
              />
            );
          })}
        </Typography>
      }
    </>
  );
};

export default ParamDetail;
