import { Box, BoxProps, styled } from '@mui/system';
import React from 'react';
import theme, { COLORS } from '../../../utils/theme';
import Typography from '../../common/Typography';

const useStyles = {
  requiredStyle: {
    color: COLORS.TEXT_SECONDARY,
    marginLeft: '2px',
  },
  typeStyle: {
    color: COLORS.TEXT_SECONDARY,
    marginLeft: theme.spacing(2),
  },
  keyStyle: {
    color: COLORS.SECONDARY_STORM,
  },
};

const StyledBox = styled((props: BoxProps) => <Box {...props} />)(() => ({
  display: 'flex',
  paddingTop: theme.spacing(2),
  alignItems: 'center',
}));

export interface ParamKeyProps {
  name: string;
  type?: string;
  required?: boolean | string;
  style?: object;
  format?: string | undefined;
}

const ParamKey: React.FC<ParamKeyProps> = (props) => {
  const { name, type, required, style, format } = props;
  return (
    <StyledBox sx={style}>
      <Typography sx={useStyles.keyStyle} type="DESKTOP_CODE_PRIMARY">
        {name}
      </Typography>
      {type && 
        <Typography sx={useStyles.typeStyle} type="DESKTOP_BODY_SMALL_TEXT">
          {type}
        </Typography>
      }
      {required && 
        <Typography sx={useStyles.requiredStyle} type="DESKTOP_CODE_SECONDARY">
          *
        </Typography>
      }
      {format && 
        <Typography sx={useStyles.typeStyle} type="DESKTOP_BODY_SMALL_TEXT">
          {'(' + format + ')'}
        </Typography>
      }
    </StyledBox>
  );
};

export default ParamKey;
