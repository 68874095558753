import React from 'react';
import { Box } from '@mui/system';
import theme from '../../../utils/theme';
import Typography from '../../common/Typography';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import MDXComponents from '../../docs/MDXComponents';
import Table from '../../common/Table';
import TableRow from '../../common/TableRow';
import TableHeader from '../../common/TableHeader';
import TableData from '../../common/TableData';
import apiRefMessages from '../../../utils/messages/en/apiRefMessages';

const useStyles = {
  scopeDetailStyle: {
    marginLeft: theme.spacing(5),
    display: 'block',
  },
  valueStyle: {
    marginBottom: theme.spacing(2),
    display: 'block',
  },
  tableStyle: {
    width: 'unset',
  },
  boxStyle: {
    marginBottom: theme.spacing(10),
  },
  headerStyle:{
    margin:'0px 0px 10px'
  }
};

const AuthInfo = ({ details }) => {
  const markdownToHtml = (description) => {
    return (
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{ ...MDXComponents }}
      >
        {description}
      </ReactMarkdown>
    );
  };
  const buildTableHeader = (type) => {
    return (
      <TableRow>
        <TableHeader>{apiRefMessages.securitySchemeType}</TableHeader>
        <TableHeader>{type}</TableHeader>
      </TableRow>
    );
  };
  const buildItemKey = (type) => {
    if (type) {
      return <TableData>{type}</TableData>;
    } else {
      return <TableData></TableData>;
    }
  };
  const buildDetailValue = (key, value) => {
    return (
      <Box sx={useStyles.valueStyle} component="span" key={key}>
        <Typography component="span" type="DESKTOP_ACTION_PRIMARY_BOLD">
          {key + ': '}
        </Typography>
        <Typography component="span" type="DESKTOP_ACTION_SECONDARY">
          {value}
        </Typography>
      </Box>
    );
  };
  const buildItemDetail = (flow) => {
    const { authorizationUrl, tokenUrl, refreshUrl, scopes } = flow;
    return (
      <TableData>
        {authorizationUrl &&
          buildDetailValue('authorizationUrl', authorizationUrl)}
        {tokenUrl && buildDetailValue('tokenUrl', tokenUrl)}
        {refreshUrl && buildDetailValue('refreshUrl', refreshUrl)}
        {scopes && 
          <>
            <Typography
              type="DESKTOP_ACTION_PRIMARY_BOLD"
              sx={useStyles.valueStyle}
              component="span"
            >
              Scopes
            </Typography>
            <Box sx={useStyles.scopeDetailStyle} component="span">
              {Object.keys(scopes).map((key) => {
                const scope = scopes[key];
                return buildDetailValue(key, scope);
              })}
            </Box>
          </>
        }
      </TableData>
    );
  };
  const buildAuthInfoTable = (detail) => {
    const { name, flow, flows } = detail;
    if (detail.in && detail.in === 'header') {
      return (
        <TableRow>
          <TableData>{apiRefMessages.headerParameterName}</TableData>
          <TableData>{name}</TableData>
        </TableRow>
      );
    } else if (flow || flows) {
      if (flow) {
        return (
          <TableRow>
            {buildItemKey(flow)}
            {buildItemDetail(detail)}
          </TableRow>
        );
      } else if (flows) {
        return Object.keys(flows).map((key) => {
          const flow = flows[key];
          return (
            <TableRow key={key}>
              {buildItemKey(key)}
              {buildItemDetail(flow)}
            </TableRow>
          );
        });
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };
  return (
    <>
      {Object.keys(details).map((key) => {
        const detail = details[key];
        return (
          <Box sx={useStyles.boxStyle} key={key}>
            <Typography variant="h2" key={key} sx={useStyles.headerStyle}>{key}</Typography>
            {detail.description && markdownToHtml(detail.description)}
            <Table sx={useStyles.tableStyle}>
              <thead>{buildTableHeader(detail.type)}</thead>
              <tbody>{buildAuthInfoTable(detail)}</tbody>
            </Table>
          </Box>
        );
      })}
    </>
  );
};

export default AuthInfo;
