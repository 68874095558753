import React from 'react';
import Chip from '@mui/material/Chip';
import { styled, Box } from '@mui/system';
import theme, { BREAKPOINTS, COLORS } from '../../../utils/theme';
import Typography from '../../common/Typography';

const useStyles = {
  method: {
    borderRadius: theme.spacing(1, 0, 0, 1),
    '& .MuiChip-label': {
      paddingTop: '2px',
    },
    height: 'unset',
    minHeight: '24px',
    [BREAKPOINTS.MOBILE]: {
      height: 'unset',
      minHeight: '24px'
    },
  },
  endpoint: {
    borderRadius: theme.spacing(0, 1, 1, 0),
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
    color: COLORS.CODE_SECONDARY,
    height: 'unset',
    minHeight: '24px',
    [BREAKPOINTS.MOBILE]: {
      maxWidth: 'calc( 100% - 60px )',
      height: 'unset',
      minHeight: '24px'
    },
  },
  endpointStyle: {
    marginTop: '10px',
    paddingLeft: '18px',
    display:'flex'
  },
};
const EndPointTypography = styled(Typography)({
  whiteSpace: 'break-spaces',
  wordBreak: 'break-all'
});
const EndPointInfo = ({ path, method }) => {
  const getStyle = (method) => {
    let style = {};
    switch (method.toLowerCase()) {
      case 'get':
        style = {
          backgroundColor: COLORS.PRIMARY_FRONT,
          color: COLORS.SECONDARY_NIGHT,
        };
        break;
      case 'put':
        style = {
          backgroundColor: COLORS.PRIMARY_DAWN,
          color: COLORS.SECONDARY_NIGHT,
        };
        break;
      case 'delete':
        style = {
          backgroundColor: COLORS.PRIMARY_DAY,
          color: theme.palette.common.white,
        };
        break;
      case 'patch':
        style = {
          backgroundColor: COLORS.PRIMARY_AIR,
          color: COLORS.SECONDARY_NIGHT,
        };
        break;
      case 'post':
      default:
        style = {
          backgroundColor: COLORS.SECONDARY_DUSK,
          color: theme.palette.common.white,
        };
        break;
    }
    return style;
  };

  return (
    <Box sx={useStyles.endpointStyle}>
      {method !== '' && 
        <>
          <Chip
            label={
              <Typography type="DESKTOP_CODE_ALLCAPS" sx={getStyle(method)}>
                {method.toUpperCase()}
              </Typography>
            }
            sx={useStyles.method}
            style={getStyle(method)}
          ></Chip>
          <Chip
            label={<EndPointTypography type="DESKTOP_CODE_PRIMARY" >{path}</EndPointTypography>}
            sx={useStyles.endpoint}
          ></Chip>
        </>
      }
    </Box>
  );
};

export default EndPointInfo;
