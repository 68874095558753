import React from 'react';
import { Box } from '@mui/system';
import theme, { COLORS } from '../../../utils/theme';
import ObjectParam from './ObjectParam';
import ParamDetail from './ParamDetail';
import ParamKey from './ParamKey';
import apiRefMessages from '../../../utils/messages/en/apiRefMessages';
import parse from 'html-react-parser';
import Typography from '../../common/Typography';
import MDXComponents from '../../docs/MDXComponents';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const useStyles = {
  content: {
    paddingLeft: '18px',
    paddingBottom: '7px',
  },
  parameter: {
    paddingLeft: '18px',
  },
  paramBox: {
    paddingBottom: '2px',
  },
  objectOpenStyle: {
    color: COLORS.TEXT_SECONDARY,
    paddingTop: '11px',
    paddingBottom: theme.spacing(2),
  },
  objectCloseStyle: {
    color: COLORS.TEXT_SECONDARY,
    paddingTop: '8px',
    paddingBottom: '12px',
  },
  reactMarkdownStyles: {
    marginBlock: 'unset',
    marginInline: 'unset',
    display: 'unset',
  },
  liStyle: {
    display: 'flex',
    marginBlock: 'unset',
    marginInline: 'unset',
  },
};
const getParamFormat = (param) => {
  if (param.format) {
    return param.format;
  } else {
    return param.schema?.format;
  }
};
export const getDescription = (property) => {
  const description = property.description;
  if (typeof description === 'string') {
    return description;
  } else {
    return undefined;
  }
};
const populateOpenBraces = (isArray, type) => {
  let openString = '';
  if (isArray) {
    openString = openString + apiRefMessages.openArrayType;
    if (type === 'string') {
      openString = openString + apiRefMessages.stringType;
    }
  }
  if (type === 'object') {
    openString = openString + apiRefMessages.openObjectType;
  }
  return openString;
};
const populateCloseBraces = (isArray, type) => {
  let closeString = '';
  if (type === 'object') {
    closeString = closeString + apiRefMessages.closeObject;
  }
  if (isArray) {
    closeString = closeString + apiRefMessages.closeArray;
  }
  return closeString;
};
export const shortCodes = {
  a: MDXComponents.a,
  ul: (props) => {
    return (
      <Typography
        type="DESKTOP_BODY_PARAGRAPH"
        sx={useStyles.reactMarkdownStyles}
        {...props}
      />
    );
  },
  li: (props) => {
    return (
      <Box sx={useStyles.liStyle}>
        <Typography
          type="DESKTOP_BODY_PARAGRAPH"
          sx={useStyles.reactMarkdownStyles}
        >
          {'-'}
        </Typography>
        <Typography
          type="DESKTOP_BODY_PARAGRAPH"
          sx={useStyles.reactMarkdownStyles}
          {...props}
        />
      </Box>
    );
  },
  p: (props) => {
    return (
      <Typography
        type="DESKTOP_BODY_PARAGRAPH"
        sx={useStyles.reactMarkdownStyles}
        {...props}
      />
    );
  },
};

const Parameter = (props) => {
  const { param, definitions, components } = props;

  const deferenceObjects = (ref) => {
    if (definitions) {
      const key = ref.replace('#/definitions/', '');
      return definitions[key];
    } else {
      const key = ref.replace('#/components/schemas/', '');
      return components.schemas[key];
    }
  };
  function buildObject(key, isArray, parentProperties, colorFlag, property, grandParentProps?) {
    let properties;
    if(property.properties){
    properties = property.properties;
    } else {
      const items = parentProperties.items;
      if(Object.keys(items).length>1){
        properties = {
          type: items
        }
      }    
    }
    return (
      <ObjectParam
        name={key}
        key={key}
        header={
          <ParamKey
            name={key}
            type={isArray ? 'array' : property.type}
            required={typeof parentProperties[key]?.required === 'boolean'?parentProperties[key]?.required: grandParentProps?.required?.includes(key)}
          />
        }
        description={getDescription(isArray ? parentProperties : property)}
        colorFlag={colorFlag}
        defaultValue={parentProperties?.default}
      >
        <Box>
          {key && 
            <Typography
              type="DESKTOP_CODE_PRIMARY"
              sx={useStyles.objectOpenStyle}
            >
              {populateOpenBraces(isArray, property.type)}
            </Typography>
          }
          {properties &&
            Object.keys(properties).map((key) => {
              let value = properties[key];
              if (value.$ref) {
                value = deferenceObjects(value.$ref);
              }
              return constructType(
                value.type,
                value,
                key,
                properties,
                !colorFlag,
                property
              );
            })}
          {key && 
            <Typography
              type="DESKTOP_CODE_PRIMARY"
              sx={useStyles.objectCloseStyle}
            >
              {populateCloseBraces(isArray, property.type)}
            </Typography>
          }
        </Box>
      </ObjectParam>
    );
  }
  const buildParamDetail = (property) => {
    return (
      <>
        <ParamDetail param={property} />
        {property.title && 
          <Typography
            whiteSpace="pre-line"
            type="DESKTOP_BODY_PARAGRAPH"
            sx={useStyles.reactMarkdownStyles}
          >
            <ReactMarkdown rehypePlugins={[rehypeRaw]} components={shortCodes}>
              {property.title}
            </ReactMarkdown>
          </Typography>
        }
        {property.description && 
          <Typography
            whiteSpace="pre-line"
            type="DESKTOP_BODY_PARAGRAPH"
            sx={useStyles.reactMarkdownStyles}
          >
            <ReactMarkdown rehypePlugins={[rehypeRaw]} components={shortCodes}>
              {property.description}
            </ReactMarkdown>
          </Typography>
        }
      </>
    );
  };
  function constructParamInfo(key, type, required, property, grandParentProps?) {
    const info = buildParamDetail(property);
    const readOnly = property.readOnly;
    const format = getParamFormat(property);
    return (
      <>
        {!readOnly && 
          <>
            <ParamKey
              name={key}
              type={type}
              required={required}
              style={useStyles.parameter}
              format={format}
            />
            {info && info.props && <Box sx={useStyles.content}>{info}</Box>}
          </>
        }
      </>
    );
  }
  function constructType(type, property, key, parentProperties, colorFlag, grandParentProps?) {
    const isArray = parentProperties.type === 'array';
    if (type === 'object' && parentProperties.fields?.type !=='object' && parentProperties.tokens?.type !=='object') {
      const properties = property.properties;
      if (properties) {
        return buildObject(key, isArray, parentProperties, colorFlag, property, grandParentProps);
      }
      else if(isArray && parentProperties.items.type === 'object') {
        return buildObject(key, isArray, parentProperties, colorFlag, property, grandParentProps);
      }
    } else if (type === 'array') {
      const arrayProperties = property.items;
      if (arrayProperties) {
        return constructType(
          arrayProperties.type,
          arrayProperties,
          key,
          property,
          colorFlag,
          grandParentProps
        );
      }
    } else if (isArray && type !== 'object') {
      return buildObject(key, isArray, parentProperties, colorFlag, property, grandParentProps);
    } else {
      return (
        <Box key={key} sx={useStyles.paramBox}>
          {constructParamInfo(key, property.type, property.required || grandParentProps?.required?.includes(key), property, grandParentProps)}
        </Box>
      );
    }
  }

  const getParamType = (param) => {
    if (param.type) {
      return param.type;
    } else {
      return param.schema.type;
    }
  };

  const constructObject = () => {
    let schema = param.schema;
    if (schema && schema.$ref) {
      schema = deferenceObjects(schema.$ref);
    }
    if (schema && schema.type === 'object') {
      const type = schema.type;
      if (type === 'object') {
        return constructType(type, schema, undefined, schema, false);
      }
    } else if (schema && schema.type === 'array') {
      const arrayProperties = param;
      arrayProperties.schema = param.schema.items;
      const type =
        apiRefMessages.openArrayType +
        getParamType(arrayProperties) +
        ' ' +
        apiRefMessages.closeArray;
      arrayProperties.schema.type = type;
      return (
        <>
          {constructParamInfo(
            arrayProperties.name,
            type,
            arrayProperties.required,
            arrayProperties,
          )}
        </>
      );
    } else {
      return (
        <>
          {constructParamInfo(
            param.name,
            getParamType(param),
            param.required,
            param,
          )}
        </>
      );
    }
    return <></>;
  };
  return <Box sx={useStyles.paramBox}>{constructObject()}</Box>;
};

export default Parameter;
